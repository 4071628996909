<template>
    <div class="sign-page">
        <div class="max-width">
            <transition>
                <div v-if="!showContract" class="text-center mt-5">
                    <img
                            width="60%"
                            :src="require('@/assets/images/complete.png')"
                            alt=""
                    />
                    <div class="text-center">
                        <h2 class="font-30 font-600 darktext--text mt-4">
                            {{ $t("global.welcome") }}
                        </h2>
                        <p class="newlineStringStyle font-18 font-600 darktext--text mt-4">
                            {{ $t("global.hospitalSign") }}
                        </p>
                    </div>
                    <v-btn
                            color="register-primary"
                            elevation="0"
                            height="50px"
                            class="radius-10 mt-3 px-10"
                            @click="viewContract"
                    >
                    <span class="white--text font-700 font-18">
                    {{ $t("global.viewContract") }}</span
                    >
                    </v-btn>
                </div>
            </transition>
            <div v-if="showContract">
                <div
                        v-if="response"
                        class="sign-page__content over-scroll"
                        v-html="response.contract_file"
                ></div>
                <v-btn
                        v-if="response"
                        color="register-primary"
                        elevation="0"
                        height="50px"
                        outlined
                        class="radius-10 mt-3 px-10"
                        @click="downloadFile"
                >
          <span class="register-primary--text font-700 font-18">
            {{ $t("global.download") }}</span
          >
                </v-btn>
                <v-btn
                        color="register-primary"
                        elevation="0"
                        height="50px"
                        class="radius-10 mt-3 px-10 mx-4"
                        @click="signHandler"
                >
          <span class="white--text font-700 font-18">
            {{ $t("global.signContract") }}</span
          >
                </v-btn>
            </div>
        </div>

        <v-dialog
                v-model="dialog"
                max-width="400px"
                content-class="radius-15 pa-4 bluebgdash"
        >
            <sign-contract isHospital @submit="submitHandler" :key="dialog"></sign-contract>
        </v-dialog>
    </div>
</template>

<script>
import SignContract from "@/components/dialogs/SignContract";

export default {
    components: {SignContract},
    data: () => ({
        dialog: false,
        showContract: false,
        token: "",
    }),
    methods: {
        downloadFile() {
            var link = document.createElement("a");
            link.setAttribute("download", "medical-contract.pdf");
            link.href = this.response.online_file;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        viewContract() {
            this.showContract = true;
        },
        signHandler() {
            this.dialog = true;
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[arr.length - 1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type: mime});
        },

        //Usage example:
        async submitHandler(val) {
            let file = this.dataURLtoFile(val.sign, "sign.png");
            let formData = new FormData();
            formData.append("hospital_sign", file);
            formData.append("signer_name", val.signerName);
            formData.append("signer_position", val.signerPosition);
            formData.append("company_registration_number", val.companyRegistrationNumber);

            let res = await this.$axios.post("hospital/contract/sign", formData, {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            });
            if (res.status == 200) {
                this.$router.push("/complete");
            } else {
                this.$store.dispatch("showSnack", {
                    text: res.errors[Object.keys(res.errors)[0]][0],
                    color: "error",
                });
            }
        },
        async getContract() {
            let response = await this.$axios.get("hospital/contract/view", {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            });
            this.response = response.data;
        },
    },
    mounted() {
        this.token = this.$route.query.token;
        if (this.token) {
            this.getContract();
        }
    },
};
</script>

<style lang="scss">
.sign-page {
  padding: 80px 20px;

  &__content {
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.newlineStringStyle {
  white-space: pre-wrap;
}
</style>
